<template>
  <div>
    <v-snackbar v-model="snackbar" color="red">
      {{ error }}
      <v-btn dark text @click="snackbar = false">Okay</v-btn>
    </v-snackbar>
    <v-stepper v-model="e6" vertical>
      <!-- Step 1 -->
      <v-stepper-step :complete="e6 > 1" step="1" editable>
        Select a file
        <small>.xls, .xlsx, .csv files supported</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-file-input
          :value="file"
          accept=".xlsx, .xls, .csv"
          label="File input"
          @change="previewFiles"
        ></v-file-input>
        <v-btn color="primary" :disabled="headers.length === 0" @click="e6 = 2"
          >Continue</v-btn
        >
      </v-stepper-content>

      <!-- Step 2 -->
      <v-stepper-step :complete="e6 > 2" step="2" :editable="e6 > 2"
        >Configure mappings for fields</v-stepper-step
      >

      <v-stepper-content step="2">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col v-for="item in items" :key="item.name" cols="12" md="6">
                <div style="text-align: start;">
                  <span>Example: {{ first[item.selected] }}</span>
                  <v-select
                    style="margin-top: 12px;"
                    :items="headers"
                    :value="item.selected"
                    persistent-hint
                    outlined
                    @input="selected => udpatedSelected(item, selected)"
                  >
                    <template slot="label"
                      >What field should I use for
                      {{ item.displayName }}?</template
                    >
                  </v-select>
                </div>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="appendAllFields"
              label="Append all fields"
            ></v-checkbox>
          </v-container>
        </v-form>
        <v-btn color="primary" :disabled="!valid" @click="e6 = 3"
          >Continue</v-btn
        >
      </v-stepper-content>

      <!-- Step 3 -->
      <v-stepper-step :complete="e6 > 3" step="3" :editable="e6 > 3">
        Preview output
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-simple-table v-if="e6 > 2" height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="item in Object.keys(final[0])"
                  :key="item"
                  class="text-left"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in final" :key="index">
                <td
                  v-for="key in Object.keys(final[index])"
                  :key="key"
                  class="text-left"
                >
                  {{ item[key] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-btn color="primary" @click="e6 = 4">Continue</v-btn>
      </v-stepper-content>

      <!-- Step 4 -->
      <v-stepper-step step="4">Upload</v-stepper-step>
      <v-stepper-content step="4">
        <v-btn color="primary" :loading="loading" @click="upload">Finish</v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import { db, patientsCollection } from '../firebase'

export default {
  props: ['Patients'],
  data() {
    return {
      e6: 1,
      valid: false,
      loading: false,
      snackbar: false,
      error: null,
      appendAllFields: true,
      items: [
        { name: 'fname', displayName: 'First Name', selected: null },
        { name: 'lname', displayName: 'Last Name', selected: null },
        { name: 'phoneNumber', displayName: 'Phone Number', selected: null },
        { name: 'email', displayName: 'Email Address', selected: null },
        { name: 'primaryDoctor', displayName: 'Primary Doctor', selected: null }
      ],
      file: null,
      sheet: null,
      headers: [],
      first: {},
      final: []
    }
  },
  watch: {
    e6: function handle(newValue) {
      if (newValue === 2) {
        this.validate()
      } else if (newValue === 3) {
        // These are the required field names.
        // We need to find the associated column from the workbook sheet
        const keys = this.items.map(item => item.name)

        for (let i = 0; i < this.sheet.length; i++) {
          // Start with an empty patient object
          const patient = {}

          // For every row in the sheet
          const row = this.sheet[i]

          for (const key of keys) {
            // Find the matching field name (field.selected)
            // Example {firstName: first_name}
            const field = this.items.find(item => item.name === key)

            // TODO: Should probably validate that field is not undefined
            patient[key] = row[field.selected]

            // Delete the column once we've matched.
            // Otherwise we'll end up with "duplicated" columns if we append the rest of the fields
            delete row[field.selected]
          }

          // This just converts the rest of the columns to appropriate field names
          const temp = {}
          Object.keys(row).forEach(key => {
            const t = key.toLowerCase().replace(/\s/g, '_')
            temp[t] = row[key]
          })

          // Final patient 'document'
          let result = {}

          if (this.appendAllFields) {
            result = {
              ...patient,
              ...temp
            }
          } else {
            result = {
              ...patient
            }
          }

          this.final.push(result)
        }
      }
    }
  },
  methods: {
    previewFiles(file) {
      const reader = new FileReader()
      reader.onload = e => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]

        const worksheet = workbook.Sheets[sheetName]
        const jsonSheet = XLSX.utils.sheet_to_json(worksheet)

        if (jsonSheet.length > 0) {
          this.sheet = jsonSheet

          const first = jsonSheet[0]
          this.first = first

          const headers = Object.keys(first)
          this.headers = headers

          // do some very basic matching based on the display name
          for (const item of this.items) {
            const name = item.displayName.split(' ')[0]
            const match = this.headers.find(header =>
              header.toLowerCase().includes(name.toLowerCase())
            )

            if (match) {
              item.selected = match
            }
          }
        } else {
          console.error('Could not find a worksheet')
          this.error = 'There was an error parsing the file.'
          this.snackbar = true
        }
      }
      reader.readAsArrayBuffer(file)
    },
    udpatedSelected(item, selected) {
      // Called when a v-select option is selected
      item.selected = selected
      this.validate()
    },
    validate() {
      // Step 2 is valid when all fields have a matched field
      const valid = this.items.filter(item => item.selected === null)
      this.valid = valid.length === 0
    },
    async upload() {
      this.loading = true
      // Get a new write batch
      const batch = db.batch()

      for (const patient of this.final) {
        const ref = patientsCollection.doc()
        batch.set(ref, patient)
      }

      // Commit the batch
      try {
        await batch.commit()
        this.resetForm()
      } catch (error) {
        console.log(error)

        this.error = 'There was an issue uploading patients'
        this.snackbar = true
        this.loading = false
      }
    },
    resetForm() {
      this.e6 = 0
      this.file = null
      this.appendAllFields = true
      this.sheet = null
      this.headers = []
      this.first = {}
      this.final = []

      for (const item of this.items) {
        item.selected = null
      }

      this.loading = false
      this.$emit('onPatientUpload')
    }
  }
}
</script>
